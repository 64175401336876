import React from 'react';
import TextControl from './textControl';

export class EmailControl extends React.Component {

    constructor(){
        super();    
        this.LoseFocus = this.LoseFocus.bind(this);
        this.state = {validEmail : null};
    }
    
    LoseFocus(name, required, value) {        
        const validator = require("email-validator");        
        const validationResult =  validator.validate(value); 
        this.setState({validEmail : validationResult});
        this.props.validation(this.props.name, validationResult);
    }
     

    render() {        
        return(
        <TextControl name='email' 
            value={this.props.value} 
            label={this.props.label} 
            fieldChange={this.props.fieldChange}  
            placeholder={this.props.placeholder}
            validation={this.LoseFocus}
            valid={this.state.validEmail}
            validationText='Please supply a valid email address'
        />
        )
    }
}

export default EmailControl;