import React, { Component } from "react";
import ReactDOM from "react-dom";
import TextControl from './textControl';
import EmailControl from './emailControl';
import axios from "axios";


const formData = { name :'', message:'', email:'' };
const validation = { name :false, message:false, email:false };


class ContactUs extends Component {
  
    constructor(props) {
        super(props);  
        this.state={formData, validation, state:'Form'};      
        this.submitForm = this.submitForm.bind(this);
      }
          

    FieldChange = (name,value) => {       
        let formData = Object.assign(this.state.formData);       
        formData[name]= value;       
        this.setState({ formData });       
     } 


     ValidationCheck = (name, value) => {
        let validation = Object.assign(this.state.validation);       
        validation[name] = value;
        this.setState({validation});        
     }

     renderButton(){
        const valid = this.state.validation['name'] && this.state.validation['email'] && this.state.validation['message'];
        const disabled = !valid ? 'disabled' : '';        
        return (
          <button disabled={disabled} onClick={this.submitForm} className="btn btn-primary">Send Message</button>
        )
     }

  renderForm(){
    return (
      <>                  
        <TextControl name='name' value={this.state.formData.name} label="Name" fieldChange={this.FieldChange} placeholder="Enter your name"
          validation={this.ValidationCheck} required={true}/>
        <EmailControl name='email' value={this.state.formData.email} label="Email" fieldChange={this.FieldChange} placeholder="Enter your email address" 
          validation={this.ValidationCheck}/>
        <TextControl textarea={true} 
            name='message' value={this.state.formData.message} 
            label="Message" fieldChange={this.FieldChange} placeholder="Enter your message"
            validation={this.ValidationCheck} required={true}/>
            {this.renderButton()}            
        </>
    );
  }

  render() {    
    let formOrMessage = '';
    if (this.state.state==="Form") {
      formOrMessage = this.renderForm();
    } else if(this.state.state==="Success") {
      formOrMessage = this.props.confirmationMessage;
    } else {
      formOrMessage = this.state.state;
    }
    return (
      <>
        {formOrMessage}
      </>
    )
  }

  submitForm(event) {
        
    const headers =  { 'Content-Type': 'application/x-www-form-urlencoded' };
    const postData = { ...this.state.formData };            
    const thisState = this;        
    axios.post('/umbraco/api/ContactUs/SubmitForm', 
      postData
    , headers)
    .then(function (response) {                    
      const responseStatus =  response.data;
      if(responseStatus==='Success') {
        thisState.setState({state:'Success'});        
      }
      else if(responseStatus==='Recaptcha Failure') {      
        thisState.setState({state:'There has been a problem submiting your form.'})
      }
    })
    .catch(function (error) {          
      console.log(error);          
    });                
  }
}
export default ContactUs;


const wrapper = document.getElementById("react-contactUs");
const className = wrapper ? wrapper.getAttribute('data-className') : null;
const confirmationMessage = wrapper ? wrapper.getAttribute('data-confirmationMessage') : null;
if (wrapper) {
  ReactDOM.render(<ContactUs  className={className} confirmationMessage={confirmationMessage}/>,wrapper);
}