import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactTable from 'react-table';
import { getTableData, getDownloadedCertsData, refreshCertificatesWebControlData, generateCertificateBatch } from "./HerdListData.js";
import 'react-table/react-table.css';
import HerdListFilter from "./herdListFilter.js";
import Pagination from "./Pagination.js";
import {DropdownButton,Dropdown,Button, Container, Row, Col} from "react-bootstrap";
import { isDate, isObject, isUndefined } from "util";
import { logout } from "./utils.js";
import { getCookie } from "../helpers/cookies";
import CertGenButton from "./CertGenButton";
import HelpTip, {getHelpTipCounter, incrementHelpTipCounter} from './HelpTip.js';
import {getPDF} from './utils';
import DownloadedCerts from "./DownloadedCerts";
import {redirectToLogin} from "./login";

const linkStyle = {fontWeight:700};

const pageSizeOptions = [10, 20, 50, 100];
const refreshInterval = 30000;

function defaultFilter() {
  return  [{id: "Dead", value: "N"}];
} 

function makeDefaultState (defaultColumns, helpTipCounter) {
    return {
    DownloadedCertsModalIsOpen: false,
    downloadedCerts: [],
    seconds: 0 ,
    data :[],
    batchesProcessing: [],
    batchAnimalIDs: [],
    error: null,
    helpTipCounter: helpTipCounter,
    numberOfRecords: 0,
    columns : defaultColumns,
    filtered:defaultFilter(),
    selectedItemsPerPage: "10",
    columnFilter : false,
    page: 0,
    filterState: {
      searchText:'',
    sex:'',
    dead: 'N',
    ageGroup : {
      min:0,
      max:4,
      rangemax: 4,
      rangemin:0,
      sliderStep:1
    },
    dob : {
      min:0,
      max:0,
      //rangemax: 0,
      //rangemin:0,
      sliderStep:1
    }
  }
    };
}
const HELPTIPCOUNTER = 'herdlist_HelpTipCounter';

class HerdList extends Component {
  
    constructor(props) {
        super(props); 

        this.applyFilter = this.applyFilter.bind(this);
        this.changeItemsPerPage = this.changeItemsPerPage.bind(this);     
        this.applyStatus = this.applyStatus.bind(this);
        this.applyStatusByBatch = this.applyStatusByBatch.bind(this);
        this.hideDownloadedCertsModal = this.hideDownloadedCertsModal.bind(this);
        this.showDownloadedCertsModal = this.showDownloadedCertsModal.bind(this);
        var helpTipCounter = getHelpTipCounter(HELPTIPCOUNTER);
        this.update = this.update.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.updateBatchStatus = this.updateBatchStatus.bind(this);
        this.submitBatch = this.submitBatch.bind(this);
        this.resetBatch = this.resetBatch.bind(this);
        this.downloadAll = this.downloadAll.bind(this);
        this.updateDataTableRow = this.updateDataTableRow.bind(this);
        this.updateDataDownloadedTableRow = this.updateDataDownloadedTableRow.bind(this);
        const defaultColumns = [
          {              
            Header: "BatchID",
            accessor: "BatchID",
            show:false
          },
          {              
            Header: "Animal_ID",
            accessor: "Animal_ID",
            show:false
          },
          {              
            Header: "SessionGUID",
            accessor: "SessionGUID",
            show:false
          },
          {              
              accessor: "Status",
              width:120,
              Cell:(cellProps) => {

                  return (
                  <>
                  <CertGenButton guid={cellProps.original.SessionGUID} userSessionGuid={getCookie("SesssionTokenID")} prefix={getCookie("Prefix")} animalName={cellProps.original.AnimalName} filePath={cellProps.original.FilePath} animalID={cellProps.original.Animal_ID} applyStatus={this.applyStatus} status={cellProps.original.Status} sexcode={cellProps.original.Sex_Code}></CertGenButton>
                  </>
                  )
                  
              }
          },
          {              
              Header: "Name",
              accessor: "AnimalName",
              className: "rt-td-left",
              minWidth:250,
              filterMethod: (filter, row) => {
                  return String(row.AnimalName).toLowerCase().includes(String(filter.value).toLowerCase()) || 
                  String(row.Line_No).includes(String(filter.value)) || 
                  String(row.HBN).toLowerCase().includes(String(filter.value).toLowerCase()) ||
                  String(row.ear_Tag).toLowerCase().includes(String(filter.value).toLowerCase()) ||
                  String(row.StrippedEarTag).toLowerCase().includes(String(filter.value).toLowerCase());
              },
              Cell:(cellProps) => {
                  var link;
                  link = "http://holstein-uk.org/animaldata/animal/ancestry/" + cellProps.original.Animal_ID

                  if(cellProps.original.Sex_Code == "19"){
                    return <>
                    <a data-tip="Please select the animal name to view the factsheet" href={link}  style={linkStyle}>
                    {cellProps.original.AnimalName}
                    <div style={{color:'red'}}>Please note this animal is ex herdbook</div>

                    </a>
                    </>                    
                  }else{
                    return <>
                    <a data-tip="Please select the animal name to view the factsheet" href={link}  style={linkStyle}>
                    {cellProps.original.AnimalName}                 
                    </a>
                    </>
                  }        
              }
          },
          {              
              Header: "Ear Tag",
              accessor: "ear_Tag",
              width:140
          },
          {      
              Header: "StrippedEarTag",        
              accessor: "StrippedEarTag",
              show: false
          },
          {              
              Header: "Line No",
              accessor: "Line_No",
              width:105
          },
          {              
              Header: "HBN",
              accessor: "HBN",
              width:140
          },
          {              
              Header: "DOB",
              accessor: "DateOfBirth",
              sliderStep: 1,
              width:100,
              Cell:(cellProps) => {
                if (cellProps.original.DateOfBirth)
                {
                  var dateFormat = require('dateformat');
                  return dateFormat(cellProps.original.DateOfBirth, "dd/mm/yyyy");
                }
                else
                {
                  return null;
                }
              },
              filterMethod: (filter, row) => {
                  
                  if ((filter.fromValue === "" || filter.fromValue===null)  && (filter.toValue === "" ||  filter.toValue===null))
                  {
                      return true;
                  }
                  else if  (filter.fromValue === "" ||  filter.fromValue== null)
                  {
                      if (!row[filter.id] == "")
                      {
                          return (new Date(row[filter.id]) <=new Date(filter.toValue) );
                      }
                  }
                  else if  (filter.toValue === "" ||  filter.toValue== null)
                  {
                      if (!row[filter.id] == "")
                      {
                          return (new Date(row[filter.id]) >=new Date(filter.fromValue) );
                      }
                  }
                  else
                  {
                      return (new Date(row[filter.id]) >= new Date(filter.fromValue) &&  new Date(row[filter.id]) <= new Date(filter.toValue));
                  }
              },
          },
          {              
              Header: "Sex",
              accessor: "Sex",
              width:80
          },
          {              
            Header: "Dead",
            accessor: "Dead",
            width:80
        },
          {
              Header: "Age Group",
              accessor: "AgeGroup",
              show:false,
              filterMethod: (filter, row) => {
                  return (row[filter.id]>=filter.fromValue && row[filter.id]<=filter.toValue)      
                }
          },
          {
            Header: "Generated",
            accessor: "Completed",
            width:130,
            Cell:(cellProps) => {
              if (cellProps.original.Completed)
              {
                var dateFormat = require('dateformat');
                return dateFormat(cellProps.original.Completed, "dd/mm/yyyy");
              }
              else
              {
                return '';
              }
            }
          }
        ]
        this.state = makeDefaultState(defaultColumns, helpTipCounter);
      }

      refresh() {
        //console.log("tick");
        this.setState(prevState => ({
          seconds: prevState.seconds + 1
        }));
        if (this.state.batchesProcessing.length > 0)
        //if any rows are of processing state then refresh the row to see if processing 
        //has finished
        {//just refresh one batch at a time and select one at random until all batches are processed
          const distinctBatches =  [...new Set(this.state.batchesProcessing)]; 
          const randomItemIndex = Math.floor(Math.random() * distinctBatches.length);
          this.refreshRow(distinctBatches[randomItemIndex]);
        }
      }
      refreshRow(batchId)
      {
        refreshCertificatesWebControlData(batchId).then(response => {
          if (response !=null)
          {
            if (response.Status !== 'P' && response.Status !== 'N')
            {
              let batchAnimalIDs = this.state.data.filter(x=>x.BatchID == batchId).map(a=>a.Animal_ID);
              this.updateBatchStatus(batchAnimalIDs, response.Status, batchId, response.SessionGUID, response.FilePath, response.Completed);


              if (response.Status == 'R' || response.Status == 'F') //if batch has processed remove from batchesProcessing list
              {
                let batchesProcessing = this.state.batchesProcessing.filter(x=>x!= batchId);
                this.setState({batchesProcessing: batchesProcessing});
                if (response.Status == 'F')
                {
                  let downloadedCerts = this.state.downloadedCerts;
                  downloadedCerts.push(response);
                  this.setState({downloadedCerts: downloadedCerts});
                }
              }
            }
          }
        })
      }

      update() {    
        var helpTipCounter = this.state.helpTipCounter;
        helpTipCounter = incrementHelpTipCounter(HELPTIPCOUNTER, helpTipCounter);    
        this.setState({helpTipCounter});
      }
      resetTable()
      {
        this.setState({filtered : [] });
        const filterState = this.state.filterState;
        filterState.sex = "";
        filterState.searchText = "";
        this.setState({filterState : filterState});
        this.setState({page : 0});
      }
      
      updateBatchStatus(batchAnimalIDs,newStatus, batchID,sessionGUID, filePath, completed )
      {
          //console.log('updateBatchStatus ' + filePath);
          batchAnimalIDs.forEach((element) => {
            this.updateRowStatus(element, newStatus, batchID,sessionGUID, filePath, completed);
          }
        )
      }

      
      updateRowStatus(animalID, newStatus, batchID,sessionGUID, filePath, completed)
      {
        //console.log('updateRowStatus ' + filePath);
        const data = this.state.data;
        const rowIndex = data.findIndex(x=>x.Animal_ID == animalID);
        this.updateDataTableRow(rowIndex, newStatus, filePath, sessionGUID, batchID, completed);
      }

      applyStatusByBatch(batchID, newStatus)
      {
        if (newStatus == "R") //only required for read status atm
        {
          if (batchID)
          {
            const data = this.state.data;
            const batchRows = data.filter(x=>x.BatchID == batchID);

            batchRows.forEach((element) => {
              this.updateDataTableRowByAnimalID(element.Animal_ID, newStatus);
            });          

          }
        }
      }

      applyStatus(animalID, newStatus) {

        const data = this.state.data;
        const rowIndex = data.findIndex(x=>x.Animal_ID == animalID);

        if (newStatus=="B") //add to batch and update status
        {
          if (this.state.batchAnimalIDs.length < 10) //allow 10 per batch only
          {
            this.state.batchAnimalIDs.push(animalID);
            this.updateDataTableRow(rowIndex, newStatus);
          }
          else
          {
            alert('Only 10 animals are allowed per batch.');
          }
        }
        else if (newStatus == "R")
        {
          const batchID = data[rowIndex].BatchID;
          if (batchID)
          {
            const batchRows = data.filter((x=>x.BatchID == batchID));

            batchRows.forEach((element) => {
              this.updateDataTableRowByAnimalID(element.Animal_ID, newStatus);
            });          
            
            let downloadedCerts = this.state.downloadedCerts;
            const rowIndex = downloadedCerts.findIndex(x=>x.BatchId == batchID);
            this.updateDataDownloadedTableRow(rowIndex, newStatus);
            
          }
        }
        else if (newStatus=="") //remove from batch and update status
        {
          let batchAnimalIDs = this.state.batchAnimalIDs;

          batchAnimalIDs = batchAnimalIDs.filter(function(value){
            return value != animalID;
          });
          this.setState({batchAnimalIDs: batchAnimalIDs});
          this.updateDataTableRow(rowIndex, newStatus);
        }
        
      }
      downloadAll()
      {
        let sessionGUIDList = this.state.data.filter(x=>x.Status == "R" || x.Status == "F").map(b=>b.SessionGUID);
        sessionGUIDList = [...new Set(sessionGUIDList)]
        sessionGUIDList.forEach((sessionGUID) => {
          let rows = this.state.data.filter(x=>x.SessionGUID == sessionGUID);
          if (rows.length > 0)
          {
            let element = rows[0];
            getPDF(getCookie('SesssionTokenID'),element.FilePath, getCookie('Prefix') + "-" + element.SessionGUID, element.SessionGUID, element.Status);
          }
        }
        )
      }
      resetBatch()
      {
        this.updateBatchStatus(this.state.batchAnimalIDs, "");
        this.setState({batchAnimalIDs: []});
      }
      submitBatch()
      {
        let batchAnimalIDs = this.state.batchAnimalIDs;
        this.setState({batchAnimalIDs: []});
        //run in batches of 10
        generateCertificateBatch(batchAnimalIDs).then(
          (result) => {   
            let batchesProcessing =  this.state.batchesProcessing;
            batchesProcessing.push(result.data);
            this.setState({batchesProcessing : batchesProcessing});
            this.updateBatchStatus(batchAnimalIDs,"P", result.data);
            //downloadedCerts.push(result.data);

            //add to batch list
          },
          (error) => {
            this.updateBatchStatus(batchAnimalIDs, "E");
          },
        )
      }

      getRowCount()
      {
        if(this.selectTable!=undefined){
          return this.selectTable.getResolvedState().sortedData.length;
        }
        return this.state.data.length;
      }
      updateDataTableRowByAnimalID(animalID, status, filePath, sessionGUID, batchID, completed)
      {
        const rowIndex = this.state.data.findIndex(x=>x.Animal_ID == animalID);
        if (rowIndex > -1)
        {
          //console.log('update ' + rowIndex);
          //console.log('updateDataTableRowByAnimalID ' + filePath);
          this.updateDataTableRow(rowIndex, status, filePath , sessionGUID, batchID, completed);
        }
      }
      updateDataDownloadedTableRow(index, status)
      {
        //console.log('updateDataTableRow ' + filePath);
        this.setState(oldState => {
          let downloadedCerts = oldState.downloadedCerts.slice();
          let copy = Object.assign({}, downloadedCerts[index]);
          copy.Status = status;// generateCertificate(1, col.Animal_ID);;;
          downloadedCerts[index] = copy;
          return {
            downloadedCerts: downloadedCerts
          };
        });
        
      }


      updateDataTableRow(index, status, filePath, sessionGUID, batchID, completed)
      {
        //console.log('updateDataTableRow ' + filePath);
        this.setState(oldState => {
          let data = oldState.data.slice();
          let copy = Object.assign({}, data[index]);
          copy.Status = status;// generateCertificate(1, col.Animal_ID);;;
          copy.BatchID = batchID;
          if (filePath && sessionGUID)
          {
            copy.FilePath = filePath;
            copy.SessionGUID = sessionGUID;
          }
          if (completed)
          {
            copy.Completed = completed;
          }
          
          copy.selected = true;
          data[index] = copy;

          return {
            data: data
          };
        });
        
      }
      renderDownloadAllButton()
      {
        return <Button block variant="primary" onClick={this.downloadAll}>Download All</Button>
      }
      renderSubmitBatchButton()
      {
        if (this.state.batchAnimalIDs.length > 0)
        {
          return <Button block variant="success" onClick={this.submitBatch} >Submit Batch</Button>
        }
        return <Button block variant="primary" disabled >Submit Batch</Button>
      }
      renderResetBatch()
      {
        if (this.state.batchAnimalIDs.length > 0)
        {
          return <Button block variant="primary" onClick={this.resetBatch} >Reset Batch</Button>
        }
        return <Button block variant="primary" disabled >Reset Batch</Button>
      }

      renderViewBatches()
      {
        if (this.state.downloadedCerts.length > 0)
        {
          return (
            <div>
              <Button block variant="primary" onClick={this.showDownloadedCertsModal} >View Completed Batches</Button>
              <DownloadedCerts 
              applyStatus={this.applyStatusByBatch} data={this.state.downloadedCerts}  hide={this.hideDownloadedCertsModal} modalIsOpen={this.state.DownloadedCertsModalIsOpen}></DownloadedCerts>
            </div> 
            )
        }
        else
        {
          return (
            <div>
          <Button block variant="primary" disabled >View Batches</Button>
          </div> 
            )
        }
        
      }


  render() {

    const error = this.state.error;
    const downloadedCertsDataisLoaded = this.state.downloadedCertsDataisLoaded;
    const tableDataisLoaded = this.state.tableDataisLoaded;

    if (error) {
      //console.log(error);
      //return <div>Your session may have expired. Please login again.</div>;
      return <div>Your session may have expired. Please login again.</div>;
    } else if (!tableDataisLoaded || !downloadedCertsDataisLoaded) {
      return <div>Loading...</div>;
    } else {
    return (
      <>
      <Container fluid>
      <Row>
        <Col>
        <div className="float-right">
        <Button onClick={logout} variant="primary" >Switch Herd</Button>
        </div>
        </Col>
      </Row>
      </Container>
    <HerdListFilter resetTable={this.resetTable} helpTipCounter={this.state.helpTipCounter} updateHelpTip={this.update} animalCount={this.getRowCount()} ApplyFilter={this.applyFilter} value={this.state.filterColumn} filterState={this.state.filterState}/>

    <Container fluid>
      <Row noGutters>
        <Col>

        <div className="float-left">
          <div className="ml-1 mr-1 mb-1 width:109px">
            {this.renderSubmitBatchButton()}
          </div>
        </div>
        <div className="float-left ml-1 mb-1 ">
          <div className="width:109px">
            {this.renderResetBatch()}
          </div>

        </div>
        
        <div className="float-left h5 mt-2 ml-2 mb-1">
          Animals Selected ({this.state.batchAnimalIDs.length})
          </div>
        </Col>
        <Col>


        <div className="float-right ml-2 mb-1">

      
        <HelpTip text="Click to change the number of animals per page" no="4" topMargin="45" leftMargin="10" updateParent={this.update} counter={this.state.helpTipCounter}/>                
        <DropdownButton id="dropdown-items-per-page"  title="Animals per page" >
      {pageSizeOptions.map((item) => (
        <Dropdown.Item 
        onSelect={this.changeItemsPerPage} eventKey={item} id={item} key={item}
        data-test= {"dropdown-items-per-page-" + item}
        className={
          (parseInt(this.state.selectedItemsPerPage) === item) ? "active": ""
        }>{item}
        </Dropdown.Item>
      ))}
      </DropdownButton>
        </div>
        
        <div className="float-right">
          <div className="width:109px  mb-1">
            {this.renderViewBatches()}
          </div>

        </div>


        </Col>
      </Row>
      </Container>

        <Container fluid>
      <Row>
        <Col>

      <HelpTip text="Click on the column headings to sort" no="5" topMargin="40" leftMargin="150" updateParent={this.update}  counter={this.state.helpTipCounter}/>
      <HelpTip text="Select to generate and download a certificate" no="6" topMargin="40" updateParent={this.update}  counter={this.state.helpTipCounter}/>
  <ReactTable
        noDataText="If your animal is not found please contact our Membership Services team on 01923 695 200 for assistance."
        key={this.state.pageSize}
        previousText="<"
        nextText=">"
        PaginationComponent={Pagination}
        defaultPageSize={10}
        page={this.state.page}
        pageSize={this.state.pageSize}
        pageSizeOptions={this.state.pageSizeOptions}
        onPageChange={page => this.setState({ page })}
        onPageSizeChange={(pageSize, page) =>this.setState({ page, pageSize })}
        data={this.state.data}
        columns={this.state.columns}
        filtered={this.state.filtered}
        onFilteredChange={this.applyFilter.bind(this)}
        ref={(r) => { this.selectTable = r;}}
        onSortedChange={(newSorted, column, shiftKey) => {column.selected = true}}
        // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
        defaultSorted={[
          {
            id: "AnimalName",
            desc: false
          }
        ]}
        
        />
 </Col>
      </Row>
      </Container>
       </>
    );
  }
  
}

  hideDownloadedCertsModal(){
    this.setState({DownloadedCertsModalIsOpen: false})
  }

  showDownloadedCertsModal()
  {
    this.setState({DownloadedCertsModalIsOpen: true})
  }


  applyFilter(newFilter, filterState){
    
    const filtered = this.state.filtered.slice();
    for( var i = 0; i < filtered.length; i++){ 
      if (filtered[i].id === newFilter[0].id) {
        filtered.splice(i, 1); 
      }
    }
    //console.log(filtered.id);
    filtered.push(newFilter[0]);
    this.setState({ filtered : filtered });
    //var lastFilterApplied = newFilter[0];
    this.setState({ filterState : filterState });
  }

  getDownloadedCertificatesData()
  {
    getDownloadedCertsData().then(
      (result) => {
        this.setState({downloadedCerts: result});
      },
      (error) => {
        console.log('error');
        this.setState({
          downloadedCertsDataisLoaded: true,
          error:error
        });
      }
    ).then(
      (result) => {
        this.setState(
          {
            downloadedCertsDataisLoaded: true
          });
      }
    )
  }

  getData(){

    getTableData()
    .then(
        (result) => {

          this.setState({data: result});
          let batchesProcessing = result.filter(x=>x.Status == 'P' || x.Status == 'N').map(b=>b.BatchID);
          
          //console.log(this.state.data.count);
          //console.log(this.state.data.filter(x=>x.status == 'P' || x.status == 'N').length);
          this.setState({batchesProcessing : batchesProcessing});
        },
        (error) => {
          this.setState({
            tableDataisLoaded: true,
            error
          });
        }
      ).then(
        (result) => {
          this.setState(
            {
            tableDataisLoaded: true
            });
        }
      )
  }

  GetAgeGroupMinMax(data)
  {
    var cols =data.map(
      function(x) {
        if (!isNaN(x.AgeGroup))
          return x.AgeGroup
        });

    var max = Math.max(...cols);
    var min = Math.min(...cols);
    
    const filterState = this.state.filterState;

    filterState.ageGroup.min = min;
    filterState.ageGroup.max = max;

    this.setState({filterState : filterState});
  }
  getSex(data)
  {
    var sex =data.map(
      function(x) {
          return x.Sex;
        });
    const distinctSexes = [...new Set(sex)]

    const filterState = this.state.filterState;

    if (distinctSexes.length==2)
    {
      filterState.sex = "";
    }
    else
    {
      filterState.sex = distinctSexes[0]; 
    }
    this.setState({filterState : filterState});

  }
  GetDOBMinMax(data)
  {
    if (this.state.tableDataisLoaded && this.state.downloadedCertsDataisLoaded)
    {
    var dates =data.map(
      function(x) {
          return new Date(x.DateOfBirth).getTime();
        });

    var cols = dates.filter(x => x > 0);
  

    const filter = this.state.filtered.filter(x=>x.id=="DateOfBirth")[0];
    
    var max = Math.max(...cols);
    var min = Math.min(...cols);

    const filterStateDob = this.state.filterState;
    
    if (min==Infinity || min==-Infinity)
    {
      if (!isUndefined(filter))
      {
        min = filter.fromValue;
      }
    }

    if (max==Infinity || max==-Infinity)
    {
      if (!isUndefined(filter))
      {
        max = filter.toValue;
      }
    }

    filterStateDob.dob.min = min;
    filterStateDob.dob.max = max;
    
    if (!filterStateDob.dob.rangemax)
    {
      filterStateDob.dob.rangemax = max;
    }
    if (!filterStateDob.dob.rangemin)
    {
      filterStateDob.dob.rangemin = min;
    }
    this.setState({filterState : filterStateDob});
  }
  }

  componentDidUpdate(prevProps, prevState){
    redirectToLogin(this.state.error);
    if(this.selectTable!=undefined)
    {
        //this.selectTable.data = this.state.data;
        if(this.state.columnFilter == prevState.columnFilter) 
        { 
          this.GetDOBMinMax(this.selectTable.getResolvedState().sortedData);
          this.GetAgeGroupMinMax(this.selectTable.getResolvedState().sortedData);
          this.setState({columnFilter : !this.state.columnFilter});
        }
    }
    if (this.state.batchesProcessing.length > 0)
    {
      if (this.state.seconds == 0)
      {
        this.setState({seconds:1});
        this.interval = setInterval(() => this.refresh(), refreshInterval);
        //console.log('new interval set as:' + this.interval);
      }
    }
    else
    {
      if (this.state.seconds > 0)
      {
        clearInterval(this.interval);
        this.setState({seconds:0});
        //console.log("Interval cleared");
      }
    }
  }

  componentDidMount(){
    this.getData();
    this.getDownloadedCertificatesData();
  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeItemsPerPage(item) 
  {     
    this.setState({ page: 0});
    this.setState({ selectedItemsPerPage: item});
    this.setState({ pageSize: parseInt(item)});
      
  }

}


export default HerdList;


const wrapper = document.getElementById("react-herdList");

if (wrapper) {
  ReactDOM.render(<HerdList/>,wrapper);
}