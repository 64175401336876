import React from 'react';


export class TextControl extends React.Component {

    constructor(props){
        super(props);              
        this.OnBlur = this.OnBlur.bind(this);
        this.OnChange = this.OnChange.bind(this);
        this.checkRequired = this.checkRequired.bind(this);
        let validationText = "";
        if(typeof(props.validationText)!=="undefined")
        {
            validationText = props.validationText;
        }
        this.state = {validationText , valid: true};
    }
    

    checkRequired(value){        
        let validationResult = true;
        if(this.props.required){
            validationResult = value!=='';
            this.setState({validationText: "Required", valid: validationResult});
        }
        this.props.validation(this.props.name,validationResult, value);
    }

    OnBlur() {        
        if(this.props.onBlur!=null){
           this.props.onBlur(this.props.value);
        }
        if(this.props.validation!=null){            
           this.checkRequired(this.props.value);
        }
    }

    OnChange(event) {
        this.checkRequired(event.target.value);
        this.props.fieldChange(this.props.name, event.target.value);        
    }

    renderInput() {
        return (
        <input type="text" 
            value={this.props.value}  
            placeholder={this.props.placeholder} 
            onChange={(event) => this.OnChange(event)} 
            onBlur={this.OnBlur}
            className="form-control"
        />
        )
    }

    renderTextArea() {
        return (
            <textarea placeholder={this.props.placeholder} 
                    onChange={(event) => this.OnChange(event)} 
                    value= {this.props.value} onBlur={this.OnBlur} className="form-control">                
            </textarea>
            )
    }

    renderValidation(){
        let message = this.state.validationText;
        if(this.state.valid && (this.props.valid || this.props.valid===null || typeof(this.props.valid) === "undefined")){
            message = "";
        } 
        return message;
    }

    render() {
        let control = null;
        if(this.props.textarea) {
            control = this.renderTextArea();
        } else {
            control = this.renderInput();
        }
        return(
        <>        <div className="form-group">
            <label>
                {this.props.label}          
            </label>
            {control} 
            <span>{this.renderValidation()}</span>
            </div>   
        </>
        )
    }
}

export default TextControl;