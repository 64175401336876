import {Form,Button, Row, Col, Container, Modal} from "react-bootstrap";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { setCookie, getCookie } from "../helpers/cookies";
import Spinner from 'react-bootstrap/Spinner'

function defaultState(redirect) {
  return {username: getCookie("Prefix"), password:'', breedId:(getCookie("Breed")=="" ? 1 : getCookie("Breed")), showModal:false, redirect: redirect, state:'start'};
}
const Breeds =
        {"breeds": 
          [
          {
            "id":1,
            "breed":"Holstein and British Friesian",
            "key":1
          },
          {
            "id":5,
            "breed":"Ayrshire",
            "key":5
          },
          {
            "id":7,
            "breed":"Brown Swiss",
            "key":7
          },
          {
            "id":4,
            "breed":"Guernsey",
            "key":4
          },
          {
            "id":2,
            "breed":"Jersey UK",
            "key":2
          },/*
          {
            "id":12,
            "breed":"Jersey Island",
            "key":12
          },*/
          {
            "id":11,
            "breed":"Montbeliarde",
            "key":11
          }
        ]
      
    }
function setAccount(sesssionTokenID, username, password, breedId, )
{
  var headers =  { 'Content-Type': 'application/x-www-form-urlencoded' };
  var postData = { SesssionTokenID : sesssionTokenID, Username: username, Password: password, SocietyId: breedId, ReCaptchaId: '' };
  return axios.post('/umbraco/api/WebDataAcount/Account', postData, headers).then(function () {  

  });

}
export function redirectToLogin(error)
{

  const expiryDate = new Date(getCookie('expiryDate')).getTime();

  if(isNaN(expiryDate) || (new Date().getTime() + (5 * 60 * 1000))> expiryDate || error)
  {
      const wrapper = document.getElementById("react-herdList");
      const redirect = wrapper ? (wrapper.getAttribute('data-redirect') ? wrapper.getAttribute('data-redirect') : '/member-login' ) : '/member-login';
      window.location.replace(redirect);
      return true;
  }
  
}
class Login extends Component {
  
    constructor(props) {
        super(props);        
        this.state = defaultState(this.props.redirect);    
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleBreedChange = this.handleBreedChange.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
      }
      handleCloseModal() {
        this.setState({ showModal: false });
      }
    
      handleShowModal() {
        this.setState({ showModal: true });
      }

      handleUsernameChange(event) {
        this.setState({username: event.target.value, state:'start'});
      }

      handlePasswordChange(event) {
        this.setState({password: event.target.value, state:'start'});
      }
     
      handleLoggedIn()
      {
        const expiryDate = new Date(getCookie('expiryDate')).getTime();
        console.log(expiryDate);
        if(!isNaN(expiryDate))
        {
          if((new Date().getTime() + (5 * 60 * 1000)) < expiryDate)
          {
            if (getCookie('SesssionTokenID'))
            {
              const wrapper = document.getElementById("react-login");
              const redirect = wrapper ? (wrapper.getAttribute('data-redirect') ? wrapper.getAttribute('data-redirect') : 'herd-list' ) : 'herd-list';
              window.location.replace(redirect);
              return true;
            }
          }
        }
      }

      handleSubmit(event) {
        this.setState({state : "started"});
        var headers =  { 'Content-Type': 'application/x-www-form-urlencoded' };
        var postData = { Username: this.state.username, Password: this.state.password, SocietyId: this.state.breedId, ReCaptchaId: '' };
        //var postData = { Username: prefix, Password: pin, SocietyId: societyId, ReCaptchaId: getCookie("recaptchaID") };
        var redirect = this.state.redirect;
        var returndata = null; 
        var thisState = this;
        var username = this.state.username;
        var password = this.state.password;
        var breedId = this.state.breedId;
        axios.post('/umbraco/api/Login/Authenticate', 
          postData
        , headers)
        .then(function (response) {   
          returndata = { SesssionTokenID: response.data.Data.SesssionTokenID, TokenExpiry: response.data.Data.TokenExpiry }          
          if(returndata.SesssionTokenID!=='LOGON_FAIL') {
            setCookie('expiryDate',response.data.Data.TokenExpiry);
            setCookie('SesssionTokenID', returndata.SesssionTokenID);          
            setCookie('Prefix', username);
            setCookie('Breed', breedId);
            setAccount(returndata.SesssionTokenID,username,  password, breedId).then(function (response) { 
              window.location.href = redirect;
            });
            
          }
          else
          {
            thisState.setState({state:'LOGIN_FAIL'})
          }
        })
        .catch(function (error) {      
          thisState.setState({state:'LOGIN_FAIL'})
          console.log(error);          
        });      

      }
      handleBreedChange(breed) {        
        this.setState({breedId: breed.target.value});  
      }

      handleCancel(event) {
        this.setState(defaultState);
      }    

      renderDefaultBreed(breed){
          if (breed === this.state.breed) {
              return 'defaultChecked';
          }
          else {
              return '';
          }
      }

      renderFailedLogon(){        
        if (this.state.state==='LOGIN_FAIL'){
          return(<div className="text-danger p-2">Login failed, please try again</div>);
        }
      }
      renderLoginButton()
      {
        if (this.state.state==='started'){
          return (
            <Button variant="primary" id="submit" disabled >
                Login
            </Button>
            )
        }
          else{
            return (
            <Button variant="primary" onClick={this.handleSubmit} id="submit">
              Login   
            </Button>)
          }
        
      }
      renderSpinner()
      {
          if (this.state.state==='started')
          {
            return (
              <Spinner animation="border" variant="primary" />
              )
        }
      }
      renderBreeds() {
        
        const radioButtons = Breeds.breeds.map(breed =>                             
            <Form.Check type="radio" value={breed.id} label={breed.breed} name="formHorizontalRadios"  checked={this.state.breedId==breed.id} key={breed.key} onChange={this.handleBreedChange}/>            
         );            
          return (
            <Form.Group controlId="formBasicPrefix">
          <Form.Label>Choose society:</Form.Label>            
            {radioButtons}            
            </Form.Group>            
            )
      }
      renderPage()
      {
        /*
        if (this.handleLoggedIn())
        {
          return <></>;
        }
        else {*/
        return (
          <Container fluid>
            <Row>
              <Col >
              <Form >
                
           { this.renderBreeds() }
            
            <Form.Group>
              <Form.Label>Prefix:</Form.Label>
              <Form.Control id="username" type="text" placeholder="Enter prefix" value={this.state.username} onChange={this.handleUsernameChange}/>          
                <Form.Text className="text-muted">
                  We'll never share your prefix with anyone else.
                </Form.Text>      
            </Form.Group>
            <Form.Group>
              <Form.Label>Pin:</Form.Label>
              <Form.Control id="password" type="text" placeholder="Enter pin" value={this.state.password} onChange={this.handlePasswordChange}/>          
                <Form.Text className="text-muted">
                  We'll never share your pin with anyone else.
                </Form.Text>      
            </Form.Group>  
            {this.renderFailedLogon()}      
            {this.renderLoginButton()}
            &nbsp;

            <Button variant="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            &nbsp;
            <Button variant="outline-primary" onClick={this.handleShowModal}>
              Forgotten pin/prefix
            </Button>
            &nbsp;
            {this.renderSpinner()}
          </Form>
          </Col>
          </Row>
          <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Forgotten pin/prefix</Modal.Title>
              </Modal.Header>
              <Modal.Body>You need to call Membership services on 03458 734 210</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Close
                </Button>            
              </Modal.Footer>
            </Modal>
          </Container>
        );
       /* }*/
      }
  render() {
    return this.renderPage()
  }
}
export default Login;


const wrapper = document.getElementById("react-login");
const redirect = wrapper ? wrapper.getAttribute('data-redirect') : null;
//wrapper ? ReactDOM.render(<Login redirect={redirect} />, wrapper) : false;

if (wrapper) {
  ReactDOM.render(<Login redirect={redirect} />,wrapper);
}