import React, { Component, Suspense } from "react";
import { Container, Button, Row, Col, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { getCookie } from "../helpers/cookies";
import HelpTip from './HelpTip.js';

const SliderFilter = React.lazy(() => import('./SliderFilter'));

class HerdListFilter extends Component {
  constructor(props) {
    super(props);
    this.filterColumn = this.filterColumn.bind(this);
    this.handleAgeGroup = this.handleAgeGroup.bind(this);
    this.handleDOB = this.handleDOB.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleDeadChange = this.handleDeadChange.bind(this);
    this.state = { filterState: this.props.filterState };
    this.updateHelpTip = this.updateHelpTip.bind(this);
    this.resetTable = this.resetTable.bind(this);

  }
  updateHelpTip() {
    this.props.updateHelpTip();
  }
  filterColumn(e) {
    const { value } = e.target;
    const filterValue = value;
    const filtered = [{ id: e.target.id, value: filterValue }];

    const filterState = this.state.filterState;

    filterState.searchText = value;

    this.setState({ filterState: filterState });

    this.props.ApplyFilter(filtered, filterState);

  }

  handleDOB(column, startValue, endValue) {
    const filtered = [{ id: column, fromValue: startValue, toValue: endValue }];

    const filterState = this.state.filterState;

    filterState.dob.min = startValue;
    filterState.dob.max = endValue;

    this.setState({ filterState: filterState });

    this.props.ApplyFilter(filtered, this.state.filterState);
  }

  handleAgeGroup(column, startValue, endValue) {
    const filtered = [{ id: column, fromValue: startValue, toValue: endValue }];
    const filterState = this.state.filterState;

    filterState.ageGroup.min = startValue;
    filterState.ageGroup.max = endValue;

    this.setState({ filterState: filterState });

    this.props.ApplyFilter(filtered, this.state.filterState);
  }

  handleCheckChange(value, event) {
    const filtered = [{ id: "Sex", value: value }];

    const filterState = this.state.filterState;

    filterState.sex = value;

    this.setState({ filterState: filterState });

    this.props.ApplyFilter(filtered, this.state.filterState);

  }

  handleDeadChange(value, event) {

    const filtered = [{ id: "Dead", value: value }];

    const filterState = this.state.filterState;

    filterState.dead = value;

    this.setState({ filterState: filterState });

    this.props.ApplyFilter(filtered, this.state.filterState);

  }
  resetTable() {
    this.props.resetTable();
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs={12}>

              <div className='rcornersTraits mt-2 mb-2'>
                <Container fluid>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                      <div className="h3 text-muted">{getCookie("Prefix")}</div>
                      <div className="display-4" >
                        <div className="text-sm-right text-lg-left">
                          {this.props.animalCount}
                        </div>
                      </div>
                      <div className="text-sm-right text-lg-left">
                        Animals listed
            </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>

                      <Suspense fallback={<div>Loading...</div>}>
                        <SliderFilter Label="Age in Years" column="AgeGroup" ApplyFilter={this.handleAgeGroup} min={this.state.filterState.ageGroup.min} max={this.state.filterState.ageGroup.max} rangemin={this.state.filterState.ageGroup.rangemin} rangemax={this.state.filterState.ageGroup.rangemax} sliderStep={this.state.filterState.ageGroup.sliderStep} maxRangeDisplayValue="3+" />
                        <HelpTip text="Drag the slider to filter by animal age group" no="0" topMargin="0" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
                      </Suspense>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>

                      <Suspense fallback={<div>Loading...</div>}>
                        <SliderFilter type="date" Label="DOB" column="DateOfBirth" ApplyFilter={this.handleDOB} min={this.state.filterState.dob.min} max={this.state.filterState.dob.max} rangemin={this.state.filterState.dob.rangemin} rangemax={this.state.filterState.dob.rangemax} sliderStep={this.state.filterState.dob.sliderStep} />
                        <HelpTip text="Drag the slider to filter by DOB" no="1" topMargin="0" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
                      </Suspense>
                    </Col>

                  </Row>

                  <Row>
                    <Col xs={12} sm={12} md={12} lg={6}>
                      <div className='mt-3 mb-2 font-weight-bold text-left'>
                        <HelpTip text="Type here to search for an Animal Name, Line No, HBN or Ear Tag" no="2" topMargin="70" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
      Animal Search&nbsp;<input className="input mt-3" type="text" value={this.state.filterState.searchText} id="AnimalName" placeholder="Search by Animal Name, Line No, HBN or Ear Tag" onChange={this.filterColumn} />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={2}>
                      <div className='mt-3'>
                        <div className='mt-3 font-weight-bold text-left'>
                          Dead
                        </div>

                        <div className='mt-3'>

                          <ToggleButtonGroup
                            name="Dead"
                            type="radio"
                            id="Dead"
                            value={this.state.filterState.dead}
                            onChange={this.handleDeadChange}
                          >
                            <ToggleButton value={""}>All</ToggleButton>
                            <ToggleButton value={"Y"}>Yes</ToggleButton>
                            <ToggleButton value={"N"}>No</ToggleButton>
                          </ToggleButtonGroup>
                          <HelpTip text="Select to filter by if animals are dead" no="4" topMargin="0" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
                        </div>
                      </div>

                    </Col>

                    <Col xs={12} sm={12} md={6} lg={2}>
                      <div className='mt-3'>
                        <div className='mt-3 font-weight-bold text-left'>
                          Sex
                        </div>

                        <div className='mt-3'>

                          <ToggleButtonGroup
                            name="Sex"
                            type="radio"
                            id="Sex"
                            value={this.state.filterState.sex}
                            onChange={this.handleCheckChange}
                          >
                            <ToggleButton value={""}>All</ToggleButton>
                            <ToggleButton value={"M"}>Male</ToggleButton>
                            <ToggleButton value={"F"}>Female</ToggleButton>
                          </ToggleButtonGroup>
                          <HelpTip text="Select to filter by animal gender" no="3" topMargin="0" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
                        </div>
                      </div>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={2}>
                      <div className='mt-5 ml-5'>

                        <div className='pt-2 float-right'>
                          <HelpTip text="Click on Reset Table to clear all the filters" no="7" topMargin="50" updateParent={this.updateHelpTip} counter={this.props.helpTipCounter} />
                          <Button onClick={this.resetTable} variant="primary" >Reset Table</Button>
                        </div>
                      </div>

                    </Col>

                  </Row>

                </Container>
              </div>




            </Col>
          </Row>

        </Container>


      </div>


    );
  }

}
export default HerdListFilter;