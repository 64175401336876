import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import {Button, Container, Row, Col} from "react-bootstrap";
import ReactTable from 'react-table';
import Pagination from "./Pagination.js";
import { getCookie } from "../helpers/cookies";
import CertGenButton from "./CertGenButton";


Modal.setAppElement('#react-modal');

class DownloadedCerts extends Component {
 
  constructor(props) {
    super();
    this.applyStatus = this.applyStatus.bind(this);
    const columns = (   
      [            
          {
              Header: "BatchId",                    
              accessor:"BatchId",
              show: false
          },
          {  
              Header: "SessionGUID",                    
              accessor:"SessionGUID",
              show: false
          },
          {  
              Header: "SocietyID",                    
              accessor:"SocietyID",
              show: false
          },
          {  
              Header: "Started",                    
              accessor:"Started",
              show: false
          }
          ,
          {              
              accessor: "Status",
              width:120,
              Cell:(cellProps) => {
                  return (
                  <>
                  <CertGenButton guid={cellProps.original.SessionGUID} 
                    userSessionGuid={getCookie("SesssionTokenID")} 
                    prefix={getCookie("Prefix")} 
                    filePath={cellProps.original.FilePath} 
                    status={cellProps.original.Status}
                    BatchID={cellProps.original.BatchId}
                    applyStatus={this.applyStatus} />

                  </>
                  )
                  
              }
          },
          {  
              Header: "Date & Time",                    
              accessor:"Completed", 
              Cell:(cellProps) => {
                if (cellProps.original.Completed)
                {
                  var dateFormat = require('dateformat');
                  return dateFormat(cellProps.original.Completed, "dd/mm/yyyy HH:MM");
                }
                else
                {
                  return '';
                }
              }
          }
          ,
          {  
              Header: "FilePath",                    
              accessor:"FilePath",
              show: false
          }
      ]);

      this.state = {
        columns : columns
      };
    }

    renderBackdrop = (props)=> {
        return <div {...props} style= {{
          position: 'fixed',
          zIndex: 1040,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#000',
          opacity: 0.5
        }} />;
      }
    applyStatus (batchID, newStatus)
      {
        if (newStatus == "R") //only required for read status atm
        {
          const data = this.props.data;
          const rowIndex = data.findIndex(x=>x.BatchId == batchID);
          this.props.applyStatus(batchID, newStatus);
        }
      }

    table = () => {
        return (
                <ReactTable 
                    data={this.props.data} 
                    columns={this.state.columns}
                    //noDataText={progenyState.isLoaded ? "No results found" : (<Loader></Loader>)}
                    PaginationComponent={Pagination} 
                    pageSize={10} 
                    defaultSorted={[
                      {
                        id: "Completed",
                        desc: true
                      }
                    ]}
                />
            
        )
    }

    on

    render() {    
      return (
           <div>
          <Modal
            className="Modal"
            overlayClassName="Overlay"
            //className="Modal"
            isOpen={this.props.modalIsOpen}
            onRequestClose={this.props.hide}
          >

<Container fluid>
 
<Row>
        <Col>
        <div className="float-right mb-2">
<Button onClick={this.props.hide} variant="primary" >Close</Button>
        </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div className="h3 text-center">
        Completed Batches
        </div>
        </Col>
        </Row>
      <Row>
        <Col>
            {this.table()}
            </Col>
      </Row>
            </Container>
          </Modal>
        </div>
      )
    }
}
/*



  class DownloadedCerts extends React.Component {
    constructor() {
      super();
      this.closeModal = this.closeModal.bind(this);
    }

   
    closeModal() {
        this.props.hide();
    }
   
    render() {

    }
  }
   */
  export default DownloadedCerts;
