import axios from "axios"
import { deleteCookie } from "../helpers/cookies";

export function logout()
{
    deleteCookie('expiryDate');
    deleteCookie("SesssionTokenID");
    window.location.replace("/login");
}

export function getPDF(userSessionGuid, filepath, filename, guid, status){

  axios('/umbraco/api/Cert/GetCert?userSessionGuid=' + userSessionGuid + '&file='  + filepath + '&guid=' + guid + "&status=" + status, {
    method: 'GET',
    responseType: 'blob'
})
.then(response => {
    const file = new Blob(
        [response.data], 
        {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
            window.location.href = fileURL;
        } else {
            a.href = fileURL;
            a.download =  filename + ".pdf";
            document.body.appendChild(a);
            a.click();
        }
},
(error) => {
  console.log(error);
  if (error.response.status === 401)
  {
    logout();
  }
  }
);

}

