import React, { Component } from "react";
import ReactDOM from "react-dom";
import scriptLoader from 'react-async-script-loader';



class GoogleMap extends Component {
  
    constructor(props) {
        super(props);              
        
      }
          

    componentWillReceiveProps({isScriptLoadSucceed}){        
        if (isScriptLoadSucceed) {            

            var map = new window.google.maps.Map(document.getElementById('map_container'), {
                zoom: 16,
                center: {lat: 52.676882, lng: -2.430622},
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            });

            const marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(52.676882, -2.430622),
              map: map              
            }); 
        }
        else{
            alert("script not loaded")
        }
    }

  render() {        
    return (
        <div id="map_container" className={this.props.className} style={{height: "600px"}}>Loading Google Map</div>
    );
  }
}
export default scriptLoader(["https://maps.googleapis.com/maps/api/js?key=AIzaSyAkpoMYjeBPoLw83ZNtvVsXBneHUCFdQbI&amp;sensor=false"])(GoogleMap);


