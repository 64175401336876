import axios from "axios";
import { getCookie } from "../helpers/cookies";
import { logout } from "./utils.js";

export function getTableData()
{
    var name ='';
    var sex = '';
    var dtFrom = '' ;
    var dtTo = '';
    var excludedead = false;
    const data = axios({
        url: '/umbraco/api/herdlist/getHerd?userSessionGuid=' + getCookie("SesssionTokenID") + '&Name=' + name+ '&Sex=' + sex  + '&dtFrom=' + dtFrom + '&dtTo=' + dtTo + '&own=false' + '&excludedead=' + excludedead,
        method: 'GET',
        responseType: 'json'
      })
      .then(function (response) {       
        return response.data
      })
    return data;

}

export function getDownloadedCertsData()
{
    var name ='';
    var sex = '';
    var dtFrom = '' ;
    var dtTo = '';
    var excludedead = false;
    const data = axios({
        url: '/umbraco/api/Cert/GetDownloadedCerts?userSessionGuid=' + getCookie("SesssionTokenID"),
        method: 'GET',
        responseType: 'json'
      })
      .then(function (response) {       
        return response.data
      }
      ,
        (error) => {
          console.log(error);
        }
      )

      
    return data;
}

export function refreshCertificatesWebControlData(batchID)
{
  const data = axios({
    url: '/umbraco/api/cert/GetLatestCertificatesWebControlData?userSessionGuid=' + getCookie("SesssionTokenID") + '&batchID=' + batchID,
    method: 'GET',
    responseType: 'json'
  })
  .then(response => {
    return response.data
  })
  return data;
}

export function generateCertificateBatch(batchAnimalIDs)
{
    var animalIDQS = JSON.stringify(batchAnimalIDs);
    var path = '/umbraco/api/Cert/PostCertificateBatch?userSessionGuid=' + getCookie("SesssionTokenID") + '&societyID=' + getCookie("Breed") + '&animalID=' +animalIDQS;
    //console.log(path);
    var headers =  {'Content-Type': 'application/json'};

    const data = axios.post(path,'',headers).then(function (response) {  
        return response
      },
      (error) => {
        if (error.response.status === 401)
        {
          logout();
        }
        else{
          return error
        }
        }
      )
      
      
    return data;
}
function generateCertificate(societyID, animalID)
{
    //console.log("animalID" + animalID);
    var path = '/umbraco/api/Cert/PostCertificate?userSessionGuid=' + getCookie("SesssionTokenID") + '&societyID=' + getCookie("Breed") + '&animalID=' + animalID;
    var headers =  {'Content-Type': 'application/json'};

    const data = axios.post(path,'',headers).then(function (response) {  
        return response
      },
      (error) => {
        if (error.response.status === 401)
        {
          logout();
        }
        else{
          return error
        }
        }
      )
      
      
    return data;
}