import React, { Component } from "react";
import { getCookie, setCookie } from "../helpers/cookies";
 
    function hintTextStyleFunction(topMargin, marginLeft) {
        var topMarginValue = Number(topMargin) + 'px'
        
        if (marginLeft === undefined)
        {
            marginLeft = 10;
        }
        var marginLeftValue = Number(marginLeft) + 'px'
        return ({
        zIndex:1000,
        position:'absolute',
        marginLeft:marginLeftValue,
        marginTop: topMarginValue
        });
    }

    export function getHelpTipCounter(name) {
        var helptipCounter = getCookie(name);
        if(helptipCounter===''){
            helptipCounter=0;
            setCookie(name, helptipCounter);
        }
        return helptipCounter;
    }

    export function incrementHelpTipCounter(name, helpTipCounter) {
        helpTipCounter ++;
        setCookie(name, helpTipCounter);
        return helpTipCounter;
    }

  const hintCloseStyle ={
      float:'right',
      cursor: 'pointer',
      marginTop:'-15px',
      marginRight:'-5px'
  }

const defaultClass = 'helpTipContainer';

class HelpTip extends Component {

    hintTextStyle ={};

    constructor(props) {
        super(props);        
        this.state = {class: defaultClass + ' d-none', stage:'loading' };        
        this.closeHelpText = this.closeHelpText.bind(this);
        this.hintTextStyle = hintTextStyleFunction(this.props.topMargin, this.props.leftMargin);
    }

    closeHelpText() { 
        this.setState({class:defaultClass + ' d-none', stage:'close'})         
        this.props.updateParent();
    }

  render() {
    var className = defaultClass;
    if(this.props.counter!==this.props.no) {
        className = className + ' d-none';
    }
    return (
        <div className={className} role="alert" style={this.hintTextStyle}>
         <span style={hintCloseStyle} onClick={this.closeHelpText} >x</span><div>
        {this.props.text}
        </div>
       
      </div>
      
        );
  }
}
export default HelpTip;