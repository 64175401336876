import React, { Component } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {getPDF} from './utils'

class CertGenButton extends Component {
 
    constructor(props) {
      super();
      this.handleGenerate = this.handleGenerate.bind(this);
      this.handleAddToBatch = this.handleAddToBatch.bind(this);
      this.handleRemoveFromBatch = this.handleRemoveFromBatch.bind(this);
      //this.state = {status: props.status}
  }
  
  handleAddToBatch(e) {
    //console.log(this.props.animalID);
  //this.setState({status: e});
  this.props.applyStatus(this.props.animalID, "B");
  }

  handleRemoveFromBatch(e) {
    this.props.applyStatus(this.props.animalID, "");
  }
  handleGenerate(e) {
      //console.log(this.props.animalID);
    //this.setState({status: e});
    this.props.applyStatus(this.props.animalID, 1);
  }

  handleView(e) {
    getPDF(this.props.userSessionGuid,this.props.filePath, this.props.prefix + "-" + this.props.guid, this.props.guid, this.props.status);
    if (this.props.animalID){
      this.props.applyStatus(this.props.animalID, "R");
    }
    else if (this.props.BatchID)
    {
      this.props.applyStatus(this.props.BatchID, "R");
    }

  }

renderButton()
{
    if (this.props.status=="E") //ERROR
    {
      return <Button variant="danger" onClick={this.handleGenerate} block>Error</Button>  
    }
    if (this.props.status=="P" || this.props.status=="N") //processing
    {
        return <Spinner animation="border" variant="primary" />
    }
    else if (this.props.status=="F") //finished - unread/not downloaded
    {
        return <Button onClick={this.handleView.bind(this)} variant="success" block>Download</Button>
    }
    else if (this.props.status=="R") //is read/downloaded
    {
        return <Button onClick={this.handleView.bind(this)} variant="secondary" block>Viewed</Button>
    }
    else if (this.props.status=="B") //is batched
    {
        return <Button onClick={this.handleRemoveFromBatch} variant="warning" block>Selected</Button>
    }
    else if(this.props.sexcode == "19"){      
        return <Button className="btn btn-primary" block disabled>Select</Button>
    }
    else{
      return <Button className="btn btn-primary" onClick={this.handleAddToBatch} block>Select</Button>  
  }
}

  on

  render() {    
    return (
    <>
        {this.renderButton()}
    </>
    )
  }
}

export default CertGenButton;