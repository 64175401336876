import React from 'react';
import ReactDOM from 'react-dom';
import GoogleMap from './components/googleMap';
import ContactUs from './components/contactUs';
import Login from './components/login';
import HerdList from './components/herdList';
import './css/App.css';
//ReactDOM.render(<Login />, document.getElementById('root'));
import * as serviceWorker from './serviceWorker';

const wrapper = document.getElementById("react-googleMap");
const className = wrapper ? wrapper.getAttribute('data-className') : null;
if (wrapper) {
  ReactDOM.render(<GoogleMap  className={className}/>,wrapper);
}

serviceWorker.unregister();
